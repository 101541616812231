<template>
    <div>
      <TextBox
        :cefrLevel="payload.cefr_level"
        :isTitle="true"
        :label="'Title'"
        placeholder="Title"
        v-model="title"
      />
      <div class="paragraphs mt-6">
        <label>Drag and drop paragraph text</label>
        <div class="paragraph mt-2">
          <div class="number">1</div>
          <Textarea
            class="textarea"
            :rows="4"
            :cefrLevel="payload.cefr_level"
            v-model="starting_sentence"
          />
        </div>
        <div class="paragraph" v-for="(item, index) in this.questions[0].question_options" :key="index">
          <div class="number">{{ index + 2 }}</div>
          <Textarea
            class="textarea"
            :rows="4"
            :cefrLevel="payload.cefr_level"
            v-model="item.value"
          />
        </div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextBox from '@/components/item/TextBox'
import Textarea from '@/components/item/Textarea'
import { v4 as uuidv4 } from 'uuid'

export default {
    components: {
      TextBox,
      Textarea,
    },
    data: () => ({
        title:'',
        starting_sentence:'',
        options: {
          selectedOption:0,
          optionsArray:[
            { value: '', option_id: uuidv4()},
            { value: '', option_id: uuidv4()},
            { value: '', option_id: uuidv4()},
          ],
        },
        questions: [
          {
            question: '',
            question_id: uuidv4(),
            solution: [],
            question_options:[
              {
                  value:'',
                  option_id: uuidv4()
              },
              {
                  value:'',
                  option_id: uuidv4()
              },
              {
                  value:'',
                  option_id: uuidv4()
              }
            ]
          },
        ]
    }),
    computed: {
        ...mapState('item', ['payload', 'isUpdate', 'isMissingRequiredInput']),
    },
    methods: {
      onSubmit() {
        // When creating an item, set the question's solution[ids] to be a list of all option_ids of the first question's options
        if (!this.isUpdate) {
          const solutionIds = this.questions[0].question_options.map(({ option_id }) => option_id)
          this.questions[0].solution = solutionIds
        }
        const data = {
          template_type: 'DND_READING',
          static_text: 'Drag the sentences into the correct order. 👇',
          title: this.title,
          starting_sentence: this.starting_sentence,
          questions: this.questions
        }
        this.$store.commit('item/setPayload', data)
      },
    },
    beforeMount(){
      if (this.isUpdate && Object.keys(this.payload).length) {
        this.title = this.payload.title || '';
        this.starting_sentence = this.payload.starting_sentence;

        // Sort the questions by the order of the first question's solution IDs
        const { question_options, solution: solutionIds } = this.payload.questions[0]
        const sortedQuestionOptions = solutionIds.map((id) => {
          return question_options.find((option) => option.option_id === id)
        })

        this.questions[0] = {
          ...this.payload.questions[0],
          question_options: sortedQuestionOptions,
        }
      }
    },
    created() {
      /**
       * Make sure that the state knows if there's a missing required input.
       * This is used in the Save button in Controls.vue
       */
      const requiredInputs = ['title', 'starting_sentence', 'questions']
      requiredInputs.forEach(input => {
        this.$watch(
          () => this[input],
          () => {
            const isMissingRequiredInput = this.questions[0].question_options.some(({ value }) => !value)
            this.$store.commit('item/setIsMissingRequiredInput', isMissingRequiredInput)
          },
          { deep: true },
        )
      })
    },
}

</script>

<style lang="scss" scoped>

.title
{
  font-size: 1.5em;
  font-weight: bold;
  padding: 10pt;
  width: 80%;
  border: unset;
  border-bottom: 3pt solid rgb(222, 222, 222);
}


.paragraphs
{
  max-width: 500pt;
  box-sizing: border-box;

  .paragraph {
    display: flex;
    margin-bottom: 15pt;

    .number {
        position: relative;
        background: #393B71;
        display: block;
        height: 21pt;
        width: 21pt;
        border-radius: 21pt;
        text-align: center;
        color: white;
        margin-top: 13pt;
        margin-right: 10pt;
    }
    .textarea {
      width: 100% ;
    }
  }
}

label {
  color: #264D98;
  font-size: 1em;
  margin-bottom: 10pt;
}





</style>